/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processaArquivo, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {Veiculo} from '../../models/veiculo'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";
import {VeiculoSCH} from "../../search/VeiculoSCH";
import {DespesaVeiculo} from "../../models/despesa_veiculo";

const url = '/veiculo'

const state = {
  all: [],
  allList: [],
  veiculo: new Veiculo(),
  veiculo_despesa: new DespesaVeiculo(),
  filtro: new Filtro()
}

const getters = {
  listaAllVeiculo: (state) => {
    return state.allList
  },

  listaVeiculo: (state) => {
    return state.all
  },
  pegaVeiculo: (state) => {
    return state.veiculo
  },
  pegaFiltro: (state) => {
    return state.filtro
  },
  pegaDespesaVeiculo: (state) => {
    return state.veiculo_despesa
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
    state.filtro.filters.addSearch(['chassi','veiculo'],  '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {veiculo_id, onSucesso, onErro}) {
    try {
      var filtro = getBaseSCH('veiculo_id', veiculo_id, '=')
      const list = (await pegaDados(url + '/get', filtro)).data
      commit('SET_VEICULO', list.data[0])
      onSucesso()
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', VeiculoSCH(perPage))
  },

  async setAllListVeiculo({commit},{onSucesso, onErro}) {
     try {
        const list = (await lista(url + '/all')).data
       commit('SET_ALL_LIST', list)
        onSucesso()
     } catch (error) {
        console.log(error)
        onErro()
     }
  },
  async setAllVeiculo({commit}, {onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get_view', state.filtro)).data
      commit('SET_VEICULOS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },

  async printVeiculo({commit}, {onSucesso, onErro}) {
    try {
      const list = (await processaArquivo(url + '/print', state.filtro))
      onSucesso(list)
    } catch (error) {
      console.log('Erro',error)
      onErro()
      nao_autorizado(error)
    }
  },



  async processarVeiculo({commit}, {acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : '/delete'), state.veiculo, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ', error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarVendaVeiculo({commit}, {item,acao, onSucesso, onErro}) {
    try {
      let res = await processar(url +'/commit_venda', item, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ', error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarDespesaVeiculo({commit}, {item,acao, onSucesso, onErro}) {
    try {
      let res = await processar(url + '/commit_despesa', item, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ', error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setVeiculo({commit}, veiculo) {
    commit('SET_VEICULO', veiculo)
  },
  setDespesaVeiculo({commit}, veiculo) {
    commit('SET_DESPESA_VEICULO', veiculo)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_VEICULOS(state, veiculo) {
    state.all = veiculo
  },
  SET_VEICULO(state, veiculo) {
    state.veiculo = veiculo
  },
  SET_DESPESA_VEICULO(state, veiculo) {
    state.veiculo_despesa = veiculo
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
