/* eslint-disable */
import {lista, nao_autorizado, pegaDados, processar} from '../../services/GenericAPI'
import {Filtro} from "../../models/filtro/filtro";
import {DespesaVeiculo} from '../../models/despesa_veiculo'
import {BaseDescSCH, getBaseSCH} from "../../search/BaseSCH";

const url = '/despesas'

const state = {
  all: [],
  allList: [],
  despesa: new DespesaVeiculo(),
  filtro: new Filtro()
}

const getters = {
  listaAllDespesas: (state) => {
    return state.allList
  },

  listaDespesas: (state) => {
    return state.all
  },
  pegaCor: (state) => {
    return state.despesa
  },
  pegaFiltro: (state) => {
    return state.filtro
  }
}

const actions = {
  searchItem({commit}, search) {
    state.filtro.filters.search = [];
  state.filtro.filters.addSearch(['descr'], '%' + search + '%', 'ilike')
  },
  async getItem({commit}, {despesas_id, onSucesso,onErro}) {
    try {
      var filtro = getBaseSCH('despesa_id', despesas_id, '=')
      const list = (await pegaDados(url+'/get', filtro)).data
      if (list.data[0] !== undefined) {
        commit('SET_DESPESA', list.data[0])
        onSucesso()
      } else {
        onErro()
      }
    } catch (error) {
      onErro()
      console.log(error)
    }
  },

  setFiltro({commit}, perPage) {
    commit('SET_FILTRO', BaseDescSCH(perPage))
  },

  async setAllListDespesas({commit}) {
    const list = (await lista(url + '/all')).data
    commit('SET_ALL_LIST', list)
  },
  async setAllDespesas({commit},{onSucesso, onErro}) {
    try {
      const list = (await pegaDados(url + '/get', state.filtro)).data
      commit('SET_DESPESAS', list)
      onSucesso()
    } catch (error) {
      console.log(error)
      onErro()
      nao_autorizado(error)
    }
  },
  async processarCor({commit}, {acao,onSucesso,onErro}) {
    try {
      let res = await processar(url + (acao === 'gravar' ? '/commit' : ''), state.despesas, acao)

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  async processarDespesas({commit}, {dados,onSucesso,onErro}) {
    try {
      console.log('processarDespesas:', dados);
      let res = await processar(url + '/commit_despesas', dados, 'gravar')

      this.dispatch('geral/setMsgSuccess', res.data.message)
      onSucesso()
    } catch (error) {
      onErro()
      console.log('error: ',error)
      this.dispatch('geral/setObjMsgError', error.response.data)
    }
  },
  setDespesa({commit}, despesa) {
    commit('SET_DESPESA', despesa)
  },
}

const mutations = {

  SET_ALL_LIST(state, filtro) {
    state.allList = filtro
  },
  SET_FILTRO(state, filtro) {
    state.filtro = filtro
  },
  SET_DESPESAS(state, despesas) {
    state.all = despesas
  },
  SET_DESPESA(state, despesa) {
    state.despesa = despesa
  },

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
