/* eslint-disable */
import {API, setRotina, setToken} from '../api'
import {acaoApi} from '../helpers/enums'
import {EventBus} from '../helpers/event-bus'
import router from '../router/router'
export const lista = (url) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('usuario_id'))
  let res = API.get(url)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}

export const processar = (url, objeto, acao) => {
  setToken(sessionStorage.getItem('token'), sessionStorage.getItem('usuario_id'))

  if (acao === acaoApi.REMOVER) {
    var res = API.delete(url, {data: objeto})
  } else {
    var res = API.post(url, objeto)
  }

  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}


export const pegaLista = (url, data) => {
  let res = API.get(url, {params: {data: data}})
  return res
}



export const pegaDados = (url, data) => {
  let res = API.post(url, data)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}


export const autentica = (url, objeto) => {
  let res = API.post(url, objeto)
  res.catch(
   (erro) => isTokenExpired(erro.response)
  )
  return res
}


const isTokenExpired = (response) => {
  if(response.status === 401){
    let renovar = (response.data.message === "Token has expired")
    EventBus.$emit('RENOVAR_LOGIN', renovar)
  }
}

export const processaArquivo = (url, objeto) => {
  var res = API.post(url, objeto, {
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'blob'
  })

  res.catch(
   (erro) => erro.response
  )
  return res
}



export const processaDados = (url, data) => {
  setToken(sessionStorage.getItem('token'))
  var headers = {
    AcaoId: sessionStorage.getItem('AcaoId'),
    UserId: sessionStorage.getItem('usuario_id')
  }

  data.headers = []
  data.headers = headers

  return API.post(url, data)
};

export const setarToken = (token) => {
  setToken(token)
}


export const nao_autorizado = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      sessionStorage.clear()
      router.push({path: '/'}).catch(err => {
      })
    }
  }
}

